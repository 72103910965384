import React from "react";
import { useState, useEffect } from "react";
import "./SubmitForm.css";
import axios from "axios";

/**
 * @author Rajesh
 * @description submit form with loanId, mobileNumber and UMRN (max amoung and dates if nach selection options is amend)
 * @param {Object} Object  { natchSelected, setOpen, setAlertType, setAlertMessage}
 * @returns submit form
 */
const SubmitForm = ({
  natchSelected,
  setOpen,
  setAlertType,
  setAlertMessage,
  setSuccess,
  setFail,
  setUniqueId,
  setErrorMessage
}) => {
  const [loanId, setLoanId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [UMRN, setUMRN] = useState("");
  const [loanMaxAmount, setLoanMaxAmount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loanIdErro, setLoanIdError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [UMRNError, setUMRNError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [commonError, setCommonError] = useState(false);
  const [maxAmountError, setMaxAmountError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    setLoanId("");
    setMobileNumber("");
    setUMRN("");
    setEmail("");
  }, [natchSelected]);

  /**
   * @description It checks if provided payload has all entries in required formate
   * @param Object payload
   * @returns true or false
   */
  const checkPayload = (payload) => {
    let payloadError = false;
    setPressed(true);

    let { loan_id, mob_no, UMRN_no, max_amount, start_date, end_date } =
      payload;

    loan_id = loan_id.trim();
    mob_no = mob_no.trim();
    UMRN_no = UMRN_no.trim();
    max_amount = max_amount?.trim();
    start_date = start_date?.trim();

    if (loan_id.length === 0 || /\s/.test(loan_id)) {
      if (loan_id.length === 0) {
        setLoanIdError(true);
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Loan Id not provided");
      } else {
        setLoanIdError(true);
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Invalid loan Id");
      }
      payloadError = true;
      setPressed(false);
    } else if (!/^[6-9][0-9]{9}$/.test(mob_no)) {
      setMobileError(true);
      setOpen(true);
      setAlertType("failure");
      setAlertMessage("Invalid mobile number");
      payloadError = true;
      setPressed(false);
    } else if (UMRN_no.length === 0 || /\s/.test(UMRN_no)) {
      if (UMRN_no.length === 0) {
        setUMRNError(true);
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("UMRN not provided");
      } else {
        setUMRNError(true);
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Invalid UMRN");
      }
      payloadError = true;
      setPressed(false);
    }
    else if (email.length > 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError(true);
      setOpen(true);
      setAlertType("failure");
      setAlertMessage("Invalid Email");
      payloadError = true;
      setPressed(false);
    }
    else if (natchSelected === "amend") {

      if (!loanMaxAmount && !start_date && !end_date) {
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Please select at least on of these");
        setCommonError(true);
        payloadError = true;
        setPressed(false);
      }
      else if (loanMaxAmount || start_date || end_date) {


        const presentDate = new Date();
        if (loanMaxAmount && !/^(0|[1-9]\d*)(\.\d{1,2})?$/.test(loanMaxAmount)) {
          setOpen(true);
          setAlertType("failure");
          setMaxAmountError(true);
          setAlertMessage("Invalid max amount");
          payloadError = true;
          setPressed(false);
          return payloadError;
        }
        else if (start_date && end_date) {
          const startDateValue = new Date(start_date);
          const endDateValue = new Date(end_date);
          if (startDateValue >= endDateValue) {
            setOpen(true);
            setAlertType("failure");
            setStartDateError(true);
            setEndDateError(true);
            setAlertMessage("Invalid Date");
            payloadError = true;
            setPressed(false);
            return payloadError;
          }

        }
      }
    }
    return payloadError;

  };

  /**
   * @author Rajesh
   * @description takes payload and hit post nach api
   * @param {Event} event
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload;
    if (natchSelected === "amend") {
      payload = {
        loan_id: loanId,
        mob_no: mobileNumber,
        UMRN_no: UMRN,
        max_amount: loanMaxAmount,
        start_date: startDate,
        end_date: endDate,
        email: email,
      };
    } else {
      payload = {
        loan_id: loanId,
        mob_no: mobileNumber,
        UMRN_no: UMRN,
        email: email,
      };
    }

    const payloadError = checkPayload(payload);
    if (!payloadError) {

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PATH}/api/nach/${natchSelected}-nach`,
          payload,
          config
        );
        if (response?.data?.data?.status === "success") {
          setUniqueId(response?.data?.data?.uniqueId);
          setOpen(true);
          setAlertType("success");
          setAlertMessage(`Your request has been successfully logged`);
          setTimeout(() => {
            setSuccess(true);
          }, 3000);
        }
        if (response?.data?.data?.status === "error") {
          setErrorMessage(response?.data?.data?.message ?? `Your request has not been successfully logged.`);//we set ErrorMessage to show this message in error page.
          setOpen(true);
          setAlertType("error");   //to open error pop-up for showing error message in right upper corner.
          setAlertMessage(response?.data?.data?.message);
          setTimeout(() => {
            setFail(true);
          }, 2500);
        }
      } catch (error) {
        setOpen(true);
        setAlertType("failure");
        setAlertMessage("Something went wrong");
      }
    }
  };

  return (
    <div className="form-container">
      <form action="" className="form">
        <div className="form-upper">
          <input
            type="text"
            placeholder="Loan ID"
            className="form-input"
            style={{ border: `${loanIdErro ? "1px solid red" : ""}` }}
            value={loanId}
            onChange={(e) => {
              setLoanId(e.target.value);
              setLoanIdError(false);
            }}
            required
          />

          <input
            type="tel"
            placeholder="Mobile Number"
            className="form-input"
            style={{ border: `${mobileError ? "1px solid red" : ""}` }}
            maxLength="10"
            pattern="[1-9][0-9]{9}"
            value={mobileNumber}
            onChange={(e) => {
              setMobileNumber(e.target.value);
              setMobileError(false);
            }}
            required
          />

          <input
            type="text"
            placeholder="UMRN"
            className="form-input"
            style={{ border: `${UMRNError ? "1px solid red" : ""}` }}
            value={UMRN}
            onChange={(e) => {
              setUMRN(e.target.value);
              setUMRNError(false);
            }}
            required
          />
          <input
            type="email"
            placeholder="Email"
            className="form-input"
            style={{ border: `${emailError ? "1px solid red" : ""}` }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            required
          />
        </div>
        {natchSelected === "amend" && (
          <div>
            <p className="form-title">
              Please enter the details which need to be amended
            </p>
            <input
              type="number"
              placeholder="Max Amount"
              className="form-input"
              style={{ border: `${(commonError || maxAmountError) ? "1px solid red" : ""}` }}
              value={loanMaxAmount}
              onChange={(e) => {
                setLoanMaxAmount(e.target.value);
                setCommonError(false);
                setMaxAmountError(false);
              }}
            />

            <div className="date-container">
              <div className="date-input">
                <input
                  type="text"
                  className="form-input"
                  placeholder="Start Date (DD-MM-YYYY)"
                  onFocus={(e) => (e.target.type = "date")}
                  style={{ border: `${(startDateError || commonError) ? "1px solid red" : ""}` }}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setStartDateError(false);
                    setEndDateError(false);
                    setCommonError(false);
                  }}
                  value={startDate}
                />
              </div>
              <div className="date-input">
                <input
                  type="text"
                  className="form-input"
                  placeholder="End Date (DD-MM-YYYY)"
                  onFocus={(e) => (e.target.type = "date")}
                  style={{ border: `${(endDateError || commonError) ? "1px solid red" : ""}` }}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setEndDateError(false);
                    setStartDateError(false);
                    setCommonError(false);
                  }}
                  value={endDate}
                />
              </div>
            </div>
          </div>
        )}
        <button
          type="submit"
          className="submit-button"
          onClick={handleSubmit}
          disabled={pressed}
        >
          Submit
        </button>
      </form>
      <div className="disclaimer">
        <strong>Disclaimer :-</strong>
        {natchSelected === 'cancel' && <>
          <p>
            By proceeding with the cancellation of your mandate, you acknowledge
            and agree that you are authorising us to terminate the mandate linked
            to your account.
          </p>
          <p>
            Once cancelled, the mandate cannot be reinstated, and you will need to
            set up a new mandate for future transactions.
          </p>
        </>}
        {natchSelected === 'suspend' && <>
          <p>
            By proceeding with the suspension of your mandate, you acknowledge
            and agree that you are authorising us to temporarily halt automatic payments associated with this mandate.
          </p>
          <p>
            This suspension will remain in effect untill you take action to either revoke or reactivate the mandate.
          </p>
        </>}
        {natchSelected === 'revoke' && <>
          <p>
            By proceeding with the revocation of your mandate, you acknowledge
            and agree that you are authorising us to revoke the suspended payments associated with the mandate.
          </p>
        </>}
        {natchSelected === 'amend' && <>
          <p>
            By proceeding with the amendment of your mandate, you acknowledge
            and agree that you are authorising us to make the specified changes to your existing mandate details.
          </p>
        </>}
      </div>
    </div>
  );
};

export default SubmitForm;
